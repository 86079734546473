import { readable, derived } from 'svelte/store';
import pathBrowserify from "path-browserify";

let thisConfig = {};

const jitsiConfigs = {
    fallback: {
        jitsiDomain: 'meet.jit.si',
        jitsiScriptURL: 'https://meet.jit.si/external_api.js'
    },
    synprime: {
        jitsiDomain: 'meet.dev.syndicatepri.me',
        jitsiScriptURL: 'https://meet.dev.syndicatepri.me/external_api.js'
    }
}

const environments = {
    dev: {
        apiEndpoint: 'https://api.dev.syndicatepri.me/', 
        amplifyAuth: {
          region: 'us-east-1',
          userPoolId: 'us-east-1_qg44JZ2s7',
          userPoolWebClientId: '49f1ttodg1oju9ormrvq5bjqt8',
          authenticationFlowType: 'CUSTOM_AUTH',
          identityPoolId: 'us-east-1:9d84ddf6-2754-4d59-9a59-e7daaa4dabb8',
          identityPoolRegion: 'us-east-1'
        },
        imageBucket: "images2.dev.syndicatepri.me",
        cognitoIdentityPoolId: "us-east-1:9d84ddf6-2754-4d59-9a59-e7daaa4dabb8",
        jitsi: {
            domain: 'meet.dev.syndicatepri.me',
            scriptURL: 'https://meet.dev.syndicatepri.me/external_api.js',
            options: {
                config: {
                    startWithVideoMuted: false,
                    startWithAudioMuted: false,
                    minParticipants: 1,
                    enableClosePage: true,
                    prejoinPageEnabled: false,
                    disableThirdPartyRequests: true,
                    disableInviteFunctions: true,
                    doNotStoreRoom: true,
                    subject: '',
                    remoteVideoMenu: {
                        disableKick: true
                    },
                },
                interfaceConfig: {
                  toolbarButtons: ['microphone', 'camera', 'closedcaptions', 'desktop', 'embedmeeting', 'fullscreen','fodeviceselection',  'profile', 'chat', 'recording','sharedvideo', 'settings', 'raisehand','videoquality', 'feedback', 'stats', 'shortcuts','tileview', 'videobackgroundblur']
                }
            }
        },
        flags: {
            forceReviewMode: false
        }
    },
    staging: {
        apiEndpoint: 'https://api.dev.syndicatepri.me/',
        imageBucket: "images2.dev.syndicatepri.me", 
        amplifyAuth: {
            region: 'us-east-1',
            userPoolId: 'us-east-1_qg44JZ2s7',
            userPoolWebClientId: '49f1ttodg1oju9ormrvq5bjqt8',
            authenticationFlowType: 'CUSTOM_AUTH',
            identityPoolId: 'us-east-1:9d84ddf6-2754-4d59-9a59-e7daaa4dabb8',
            identityPoolRegion: 'us-east-1'
          },
        cognitoIdentityPoolId: "us-east-1:9d84ddf6-2754-4d59-9a59-e7daaa4dabb8",
        jitsi: {
            domain: 'meet.dev.syndicatepri.me',
            scriptURL: 'https://meet.dev.syndicatepri.me/external_api.js',
            options: {
                config: {
                    startWithVideoMuted: false,
                    startWithAudioMuted: false,
                    minParticipants: 1,
                    enableClosePage: true,
                    prejoinPageEnabled: false,
                    disableThirdPartyRequests: true,
                    disableInviteFunctions: true,
                    doNotStoreRoom: true,
                    subject: '',
                    remoteVideoMenu: {
                        disableKick: true
                    },
                },
                interfaceConfig: {
                }
            }
        },
        flags: {
            forceReviewMode: false
        }
    },
    prod: {
        apiEndpoint: 'https://api.insightgateway.com/', 
        imageBucket: "images.insightgateway.com",
        cognitoIdentityPoolId: "us-east-1:19f00777-b88a-4f02-ae76-b9308e56604c",
        amplifyAuth: {
            region: 'us-east-1',
            userPoolId: 'us-east-1_tDmdaLdU7',
            userPoolWebClientId: '5r0chj6omc4ai5i9dt0f3ehd81',
            authenticationFlowType: 'CUSTOM_AUTH',
            identityPoolId: 'us-east-1:19f00777-b88a-4f02-ae76-b9308e56604c',
            identityPoolRegion: 'us-east-1'
        },
        jitsi: {
            domain: 'meet.insightgateway.com',
            scriptURL: 'https://meet.insightgateway.com/external_api.js',
            options: {
                config: {
                    startWithVideoMuted: false,
                    startWithAudioMuted: false,
                    minParticipants: 1,
                    enableClosePage: true,
                    prejoinPageEnabled: false,
                    disableThirdPartyRequests: true,
                    disableInviteFunctions: true,
                    doNotStoreRoom: true,
                    subject: '',
                    remoteVideoMenu: {
                        disableKick: true
                    },
                },
                interfaceConfig: {
                }
            }
        },
        flags: {
            forceReviewMode: false
        }
    }
}

export const currentEnv = readable("dev",
    function start(set){
        if (process.dev) {
            set("dev")
        }
        else {
            set("staging") //temp, use prod for deployment
        }
        return function stop(){}
    })

//import this to get your current config
export const envConfig = derived(currentEnv, $currentEnv => (environments[$currentEnv]))

if(process.dev){
	Object.assign(thisConfig, {
		//apiEndpoint : "https://api.dev.syndicatepri.me/"
        ...environments.dev
	});
} else {
    Object.assign(thisConfig, {
    //apiEndpoint : "https://api.dev.syndicatepri.me/"
        ...environments.staging
    });
}

export let amplifyAuth = thisConfig.amplifyAuth;
export let imageBucket = thisConfig.imageBucket;
export let jitsi = thisConfig.jitsi;
export let flags = thisConfig.flags;
export let cognitoIdentityPoolId = thisConfig.cognitoIdentityPoolId;

export let createEndpoint = function(...endpoint){
	return thisConfig.apiEndpoint + pathBrowserify.join(...endpoint);
}