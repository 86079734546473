import { writable, readable, derived } from 'svelte/store';
import { currentUser, sendRequest } from "./Users.js";

export let participants = writable({});
export let participantGroups = writable([]);
export let groupCount = writable(0);

export let timeslots = writable([]); //[{starttime: x, duration: y},...]
export let meetings = writable({}); // {timeslotindex: [users]}

let CHECK_ACTIVE = 1000;
let CHECK_SCHEDULE = 30000;
let REVIEW_DURATION = 1;

let scheduleChecker;
let activeChecker;
//fetch upcoming schedule from endpoint
export let upcomingMeetings = derived([currentUser], async function([$currentUser], set){
	if(scheduleChecker){
		clearTimeout(scheduleChecker);
	}
	if(activeChecker){
		clearTimeout(activeChecker);
	}
	
	if (!$currentUser.isValid) {
        console.error("User session is invalid");
	}

	if(!$currentUser || !$currentUser.isValid){
		return set([]);
	}
    console.log("currentuser: " + $currentUser)
    //getUserEvents()
    //console.log(fetchUserEvents($currentUser.uuid))
	getSchedule($currentUser.email, set)
}, []); 

export let userEvents = derived([currentUser], async function([$currentUser], set){
    if(!$currentUser || !$currentUser.isValid){
		return set([]);
    }
})

export let pastEvents = derived([currentUser], async function([$currentUser], set){
    if(!$currentUser || !$currentUser.isValid){
		return set([]);
    }
    else {
        //console.log($currentUser.uuid)
        //let userEvents = await fetchUserEvents($currentUser.uuid);
        let userEvents = await getRecentEvents($currentUser.email)
        return set(userEvents)
        //console.log(userEvents)
        //return set(userEvents)
    }
});

export let pastEventMap = derived([currentUser], async function([$currentUser], set){
    if(!$currentUser || !$currentUser.isValid){
		return set({});
    }
    else {
        let evMap = {}
        getRecentEvents($currentUser.email).then((userEvents) => {
            userEvents.forEach(function(userEvent,index) {
                console.log(userEvent);
                evMap[userEvent.Id] = userEvent;
            })
            console.log(evMap);
            return set(evMap);
        })
    }
});



//returns user info by email or user id
export let userLookup =  derived([currentUser], async function([$currentUser], set){
    if(!$currentUser || !$currentUser.isValid){
		return set({});
    }
    else {
        let lookupTable = getUsers().then((users) => userListToEmailLookup(users)).catch((e) => console.log(e));
        console.log(lookupTable)
        set(lookupTable)
    }
})

function userListToEmailLookup(userList) {
    let lookupTable = {}
    userList.forEach(userInfo => {
        lookupTable[userInfo.email] = userInfo
        lookupTable[userInfo.UserId] = userInfo
    })
    return lookupTable
}



//export let meeting
/*
export let userRole = derived([currentUser], async function([$currentUser], set){
  if(!$currentUser || !$currentUser.isValid){
    return set("None");
  }
  else {
      try {
        let userid = await $currentUser.uuid;
        console.log("looking up role")
        const lookupTable = await $userLookup;
        let currentEvent = await $nextMeeting;
        console.log(lookupTable)
        let userdata = lookupTable[userid]
        if ("eventMetadata" in userdata){
          let meetingId = $nextMeeting.Id
          if(userdata["eventMetadata"][meetingId]){
            console.log("MATCH", userdata["eventMetadata"][meetingId])
            return set("Seller")
          }
          else {
            return set("Buyer")
          }
        }
        else {
          return set("Buyer")
        }
      }
      catch (e) {
        console.error("error setting user role", e)
        return set("Buyer")
      }
  }
});
*/
export let productLookup = derived([currentUser], async function([$currentUser], set){
    if(!$currentUser || !$currentUser.isValid){
		  return set({});
    }
    else {
      return set({});
    }
  })
    /*
    else {
          try {
              console.log("DOING PRODUCT LOOKUP")
              let userid = $currentUser.uuid;
              const lookupTable = await $userLookup;
              console.log(lookupTable)
              let userdata = lookupTable[userid]
              let displayData = {}
              console.log(userdata)
              //check if metadata shows user is acting as vendor
              if ("eventMetadata" in userdata) {
                console.log("event metadata", userdata["eventMetadata"])
                displayData["eventMetadata"] = userdata["eventMetadata"]
                /*
                  if (eventid in userdata["eventMetadata"] && userdata["eventMetadata"][eventid]["isVendor"] ){
                      console.log("FOUND METADATA");
                      let metadata = userdata["eventMetadata"][eventid];
                      console.log(JSON.stringify(metadata));
                      displayName = metadata.name;
                      displayCompany = metadata.company;
                      displayPhoto = metadata.imageLink;
                      displayNotes = metadata.description;
                      view = "buyer";
                      return true;
                  }
                  *//*
              }
              displayData["displayName"] = userdata.fullName;
              displayData["displayCompany"] = userdata.companyName;
              if ("profileInfo" in userdata) {
                  if ("location" in userdata.profileInfo) {
                      displayData["displayLocation"] = userdata.profileInfo.location;
                  }
                  if ("photo" in userdata.profileInfo) {
                      displayData["displayPhoto"] = userdata.profileInfo.photo;
                  }
                  if ("notes" in userdata.profileInfo) {
                      displayData["displayNotes"] = userdata.profileInfo.notes;
                  }
              }
              return set(displayData)
          } catch {
              console.log("Error performing lookup")
              return set({})
          }
      }
})*/


export let userNameLookup = writable({});
export let userIdLookup = writable({});
export let meetingInfo = writable({})

async function getUsers() {
    let reducedList = []
    try {
        let resp = await sendRequest({
            path: ["admin/users"]
        })
        if(resp.status == 200 ){
            let info = await resp.json();
            info.forEach(userInfo => {
                let reducedInfo = userInfo
                delete(reducedInfo.Events)
                reducedList.push(reducedInfo)
            })
        }
        else {
            console.log("not able to retrieve users")
        }
    }
    catch (err) {
        console.log(err)
    }
    finally {
        return reducedList
    }
}



//fetch upcoming schedule from endpoint
export let nextMeeting = writable();
export let followingMeeting = writable();

/*
export let userRole = derived([userLookup, currentUser, nextMeeting], async function([$userLookup, $currentUser, $nextMeeting], set){
  if(!$currentUser || !$nextMeeting || !userLookup) {
    return set("None")
  }
  else {
    console.log("trying role lookup")
    let role = "Buyer1"
    let userId = JSON.stringify($currentUser.uuid);
    console.log("Role lookup Id", userId);
    try {
        let meetId = $nextMeeting.eventId;
        console.log($nextMeeting)
        console.log("role meetId", meetId)
        let userEvents;])
        if (userEvents && userEvents[userId]) {
            console.log("userrole events", userEvents[userId])
            if (("eventMetadata" in userEvents[userId]) && (meetId in userEvents[userId]["eventMetadata"])){
                let userMetadata = userEvents["eventMetadata"][meetId];
                console.log("user role metadata", userMetadata);
                return set("Seller")
          }
        }
        else {
          return set("BuyerNoCatch")
        }
    }
    catch (e) {
      console.log("error somewhere")
      return set("Buyer")
    }
})
*/
let fetchUserEvents = async function(userUuid){
    try {
        let resp = await sendRequest({
            path: ["admin/users", userUuid]
        });
        if(resp.status == 200){
            let info = await resp.json();
            console.log("got info", info)
            if(info.length > 0){
                let userInfo = info[0]
                let eventSet = new Set(userInfo.events)
                console.log("eventSet", eventSet)
                let eventList = Array.from(eventSet)
                console.log("eventList", eventList)
                return eventList
            }
            else {
                return []
            }
        }
        else return []
    }
    catch (err) {
        console.log(err)
        console.log("no events from user")
        return []
    }
}


let getRecentEvents = async function(user){
	let response = await sendRequest({
		path : ["admin/events", "?user=" + encodeURIComponent(user)]
	});
	if (response.status != 200){
		console.log("user schedule not found");
		return [];
	} else {
        let allEvents = [];
			let data = await response.json();
			//append all meetings and timeslots together in case of multiple events
			let slotcount = 0;
            data.Items.forEach(function(userevent, i) {
                allEvents.push(userevent);
            })
            return allEvents;
    }
}


let getSchedule = async function(user, set){	
	let response = await sendRequest({
		path : ["admin/events", "?user=" + encodeURIComponent(user)]
	});
	
	if (response.status != 200){
		console.log("user schedule not found")
		set([]);
		return;
	} else {
		let meetings = [];
		let timeslots = [];
		try {
			let data = await response.json();
			//append all meetings and timeslots together in case of multiple events
			let slotcount = 0;
			data.Items.forEach(function(userevent, i){
        let tempmap = {}
        let eventGroups = []
        let groupOfCurrentUser = "default"
				console.log(userevent)
				if (userevent.hasOwnProperty('Timeslots') && (Array.isArray(userevent.Timeslots))) {
					userevent.Timeslots.forEach(function(timeslot, j){
						tempmap[j] = slotcount++;
						timeslots.push(timeslot)
					})
        }
        if (userevent.hasOwnProperty('Groups')) {
          for (let groupname in userevent.Groups) {
            if (userevent.Groups.hasOwnProperty(groupname)) {
              eventGroups.push({[groupname]: userevent.Groups[groupname]})
              let groupArray = userevent.Groups[groupname];
              if (groupArray.includes(user.toString())){
                console.log("found user in group", groupname)
                groupOfCurrentUser = groupname;
              }
              else {
                
              }
            }
          }                
				}
				if (userevent.hasOwnProperty('Meetings') && (Array.isArray(userevent.Meetings))) {                
					userevent.Meetings.forEach(function(meeting){
						let newid = tempmap[meeting.timeslotid]
                        meeting.timeslotid = newid
                        meeting.eventId = userevent.Id;
                        meeting.groups = eventGroups;
                        meeting.currentGroup = groupOfCurrentUser;
						meetings.push(meeting)
					});
				}
			});
			
			checkActive(user, meetings, timeslots, set);
		}
		catch (e){
			set([]);
		}
	}

	scheduleChecker = setTimeout(function(){
		clearTimeout(activeChecker);
		getSchedule(user, set);
	}, CHECK_SCHEDULE);
}

let checkActive = function(user, meetings, timeslots, set){
	try {
		let now = new Date();
		let nowtime = now.getTime();
		let times_included = [];
		let clean_array = [];
		let meetingIds = []

		// only meetings for current user
		// has some hacky checks to be sure timeslots and meetings aren't empty lists
		console.log("prefilter")
		console.log(meetings);
		let filtered = meetings.filter(function(meet){
			return meet.participants.includes(user) && (typeof(meet.timeslotid == 'number') && (typeof(timeslots[meet.timeslotid]) != 'undefined'));
		});
		console.log(filtered)
		if(filtered.length > 0) {
			times_included = filtered.map(meeting => {
				let newObj = meeting;
				newObj.timeslot = timeslots[meeting.timeslotid];
				newObj.meeting_link =  meeting.Id.toString();

				// determine time meetings starts
				newObj.start_time = new Date(meeting.timeslot.start_time);
				newObj.end_time = new Date(newObj.start_time.getTime() + (newObj.timeslot.duration*60*1000));
				newObj.review_time = new Date(newObj.end_time.getTime() + (REVIEW_DURATION*60*1000));
				
				// if meeting is over, mark it past
				if (newObj.start_time.getTime() > now.getTime()){
					newObj.status = "scheduled";
				}
				else if ((newObj.start_time.getTime() <= now.getTime()) && (newObj.end_time.getTime() >= now.getTime())){
					newObj.status = "active";
				}
				else if ((newObj.end_time.getTime() < now.getTime()) && (newObj.review_time.getTime() > now.getTime())) {
					newObj.status = "review";
				}
				else {
					newObj.status = "past";
				}
				console.log(newObj.status);
				// only return meetings that are not in the past
				if (newObj.review_time.getTime() > now.getTime()) {
					return newObj;
				}
			});
			console.log("time included")
			console.log(times_included)
			//sort by starttime
			times_included.sort(function(a, b){
				return a.start_time.getTime() - b.start_time.getTime()
			});

			//create a new array with all meetings in order
			if (times_included.length > 0) {
				for(let i = 0; i < times_included.length; i++){
					if((typeof(times_included[i]) != 'undefined') && times_included[i]){
						if ((times_included[i]).hasOwnProperty('Id') ) {
							if (!(meetingIds.includes(times_included[i].Id))) {
								console.log(meetingIds);
								meetingIds.push(times_included[i].Id)
								clean_array.push(times_included[i]);
							}                   
						}   
					}
				}
				console.log(clean_array);
			}

			set(clean_array);
			if(clean_array.length){
				nextMeeting.set(clean_array[0]);
            }
            else {
                nextMeeting.set();
            }
            if(clean_array.length > 1){
                followingMeeting.set(clean_array[1]);
            }
            else {
                followingMeeting.set();
            }
		}
	} catch(e){
		console.log("Checking schedule had an error: " + e)
	}

	activeChecker = setTimeout(function(){
		checkActive(user, meetings, timeslots, set);
	}, CHECK_ACTIVE);
}