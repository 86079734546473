import { writable, readable, derived, get } from 'svelte/store';
import { CognitoUser, CognitoUserSession, CognitoUserPool, CognitoAccessToken } from 'amazon-cognito-identity-js';
//import { CognitoCachingCredentialsProvider } from '@aws-amplify/api'
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import { amplifyAuth, createEndpoint, cognitoIdentityPoolId } from "./Config.js";

const requiredParams = ["username", "authToken", "session"];

Amplify.configure({
    Auth: amplifyAuth
    
});

const GROUPS = {
	guest: "guests", 
	admin: "admins", 
	user: "users", 
	default: "guests"
}

export const groupNames = readable(GROUPS);

export const guestUser = {
	group : GROUPS.guest,
	sessionInfo : {},
	idToken : "",
	email : "",
    isValid : false,
    uuid: "0000000-0000-00000",
    guest : true,
    displayName: "Guest"
};

export const authToken = writable("")
export const currentUser = writable("")

let loggedInUser;


export let login = async function(){
	if(loggedInUser){
		return await loggedInUser;
	}

	loggedInUser = Promise.resolve().then(async function(){
		let user = {};
		console.log("calling auth");
		//set user info if it's available

		let sess;
		try {
			sess = await Auth.currentSession();
			user.isValid = sess.isValid();
		} catch(e){}

		try {
			if(!user.isValid) {
				console.log("No saved user")
				sess = await magicLink();
				user.isValid = sess.isValid();
			}
			
			if(user.isValid){
				localStorage.removeItem("sessionLoginToken");
				console.log("its valid");
				user.sessionInfo = JSON.stringify(sess);
				//console.log($sessionInfo);
				user.idToken = sess.getIdToken().getJwtToken();
				// console.log($idToken)
                let decodedId = sess.getIdToken().decodePayload();
                console.log("decoding")
                console.log(decodedId);
                //todo: better check to see if set
                user.displayName = decodedId.name || "User";
                user.uuid = decodedId.sub || "0000-0000000-00000"
                        //console.log(sess.getIdToken().payload);
				user.email = decodedId.email;
				if (("cognito:groups") in decodedId){
					if (decodedId["cognito:groups"].includes(GROUPS.admin)) {
						user.group = GROUPS.admin;
						user.admin = true;
					}
					else {
						user.group = GROUPS.user; //fallback
					}
				} else {
					user.group = GROUPS.user;
				}
			} else {
				user = guestUser;
			}		

            currentUser.set(user);
            return user;
            /*
            try {
                //nitialize the Amazon Cognito credentials provider
                const credentialsProvider = new Auth.CognitoCachingCredentialsProvider(
                    "us-east-1:ca3226ea-21e3-45b5-a5e5-582c6bfa1460", // Identity pool ID
                    Regions.US_EAST_1 // Region
                );
            } catch (err) {
                console.log("error getting creds provider");
                console.error(err);
            }
            */

		} catch(error){
			//if not, clear out those store variables and use "Guest"
			currentUser.set(guestUser);
			return guestUser;
		}
	});
}
login();

authToken.subscribe(function(){
	if(get(authToken)){
		loggedInUser = null;
		login();
	}
});

/*
async function IdPoolAuth(user){
    let pool = `cognito-idp.${amplifyAuth.region}.amazonaws.com/${amplifyAuth.userPoolId}`;
    let idParams = {
        IdentityPoolId: cognitoIdentityPoolId,
        Logins: {
            pool: user
        }
    }
}
*/

function magicLink(){
    console.log("started magic link")
	let queryParams = parseMagicLink();
    if (queryParams){
        let cognitoUser = getCognitoUser(queryParams["username"], queryParams["session"]);
        if (typeof(cognitoUser) != 'undefined'){
            return Auth.sendCustomChallengeAnswer(cognitoUser, queryParams["authToken"]).then(function(){
                return Auth.currentSession().then( sess => {
                    if(sess.isValid()){
                        return sess;
                    } else {
                        return  Promise.reject('Invalid Session received')
                    }
                })
			}).catch(function(error){ 
				console.log(error); 
				return Promise.reject(new Error('Bad auth token'));
			});
        }
    }
    console.log("failed to login by magicLink")
    return Promise.reject(new Error('Missing Parameters required to login by link'));
}

function parseMagicLink(){
	let missingParams = [];
	let queryParams = Object.fromEntries((new URLSearchParams(window.location.search)).entries());

    requiredParams.forEach(function(param){
        if(!(param in queryParams) || (typeof(queryParams[param]) == 'undefined')){
            missingParams.push(param)
        }
	});
	
    //if session is the only thing missing, try to fetch it from local storage
    if (missingParams.length == 1 && missingParams.includes("session")){
        console.log("trying to fetch sessionLoginToken")
        if(localStorage.getItem("sessionLoginToken")) {
            queryParams["session"] = localStorage.getItem("sessionLoginToken");
            missingParams = []
        }
	}
	
    if( missingParams.length > 0 ){
        console.log("not possible to login from link due to missing query params: ", missingParams);
        return false;
    }
    else {
        return queryParams;
    }
}

//create a cognito user object to pass to custom challenge
export function getCognitoUser(username, session){
	let userPoolData = {
		UserPoolId: amplifyAuth.userPoolId,
		ClientId: amplifyAuth.userPoolWebClientId
	}
	//console.log(userPoolData)
	let cognitoUserPool = new CognitoUserPool(userPoolData);
	let cognitoUser = new CognitoUser({
		Username: username,
		Pool: cognitoUserPool
	});
	cognitoUser.Session = session;
	cognitoUser.setAuthenticationFlowType(amplifyAuth.authenticationFlowType);
	return cognitoUser;
}

let getApiHeaders = function(){
	let newHeaders = Object.assign({}, {
		'Content-Type': 'application/json'
	});

	if(get(currentUser).idToken){
		newHeaders['Authorization'] = "Bearer "+ get(currentUser).idToken;
	}

	return newHeaders;
}

export let sendRequest = async function(options){
	await login();

	console.log("Send request: " + JSON.stringify(options));

	let path = createEndpoint(...options.path)
	let payload = {
		method: options.method || 'GET', 
		mode: options.mode || 'cors', 
		body: options.body,
		headers: options.headers || getApiHeaders()
	}
		
	console.log("Sending request to " + path + " with payload: " + JSON.stringify(payload));

	return await fetch(path, payload);
}

export let signOut = async function(){
	await Auth.signOut();
	loggedInUser = null;
	return login();
}