<script>
	import { goto } from '@sapper/app'
	import { currentUser } from "../stores/Users.js";
	import Icon from "../icons/Icon.svelte";
	import GoCalendar from 'svelte-icons/go/GoCalendar.svelte'
	import MdPersonAdd from 'svelte-icons/md/MdPersonAdd.svelte'
	import classNames from "classNames";
    import { stores } from "@sapper/app";
	const { page } = stores();

	$: path = $page.path;

	$: isModeEvents = path && (path.toLowerCase() === "/admin" || path.toLowerCase().startsWith("/admin/events"));
	$: isModeUsers = path && path.toLowerCase().startsWith("/admin/users");

	$: modeStyle = classNames({
		"menu-events-selected" : isModeEvents,
		"menu-users-selected" : isModeUsers
	});

	$: iconEventsStyle = classNames("icon-button", {
		"events-selected" : isModeEvents,
		"events" : !isModeEvents
	});

	$: iconUsersStyle = classNames("icon-button", {
		"users-selected" : isModeUsers,
		"users" : !isModeUsers
	});

</script>

<style type="text/scss">
	@import "./../css/global.scss";

	#Menu {
		@extend .nav-padding;
		width: 8vmin;
	}

	.icon-button {
		@extend .pointer;
		@extend .hover-brightness;
		@extend .flex-column;
		@extend .center-horizontal;
		@extend .center-vertical;
		width : 8vmin;
		height: 8vmin;
		margin-top: 1vmin;
	}

	.icon {
		@extend .center-horizontal;
		@extend .center-vertical;
		height: 6vmin;
		transform: translateX(-3px)
	}

	.events {
		color : $primaryOrange;
	}

	.events-selected {
		background-color : $primaryOrange;
		color : white;
	}

	.menu-events-selected {
		border-right: $primaryOrange solid 8px;
	}

	.users {
		color : $primaryGreen;
	}

	.users-selected {
		background-color : $primaryGreen;
		color : white;
	}

	.menu-users-selected {
		border-right: $primaryGreen solid 8px;
	}
</style>

<div id="Menu" class={modeStyle}> 
	<div class={iconEventsStyle} on:click={() => goto("/admin")}>
		<div class="icon">
			<GoCalendar />
		</div>
	</div>
	<div class={iconUsersStyle} on:click={() => goto("/admin/users")}>
		<div class="icon">
			<MdPersonAdd />
		</div>
	</div>
</div>