<script>
    //NOTE: This is boilerplate from Sapper
	export let segment;
	import Nav from "../components/Nav.svelte";
	import AdminMenu from "../components/AdminMenu.svelte";
	import { currentUser } from "../stores/Users.js";
</script>

<style type="text/scss">
	@import "./../css/global.scss";
</style>

<div class="flex-column max-height">
	{#if !$currentUser.guest}
		<Nav {segment}/>
	{/if}
	<div class="flex-row flex">
	
		{#if $currentUser.admin}
			<AdminMenu {segment}/>
		{/if}
		<div class="flex">
			<slot ></slot>
		</div>
	</div>	
</div>