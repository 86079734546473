<script>
	import { goto } from '@sapper/app'
    import CountDownClock from "./CountDownClock.svelte";
	import { currentUser } from "../stores/Users.js";
	import Icon from "../icons/Icon.svelte";
	import classNames from "classNames";
	import { onMount } from 'svelte';
	import { nextMeeting, upcomingMeetings } from "../stores/Meetings.js";

	let navigateHome = function(){
		goto("/");
	}
</script>

<style type="text/scss">
	@import "./../css/global.scss";

	#Nav {
		position: absolute;
		height : 8vmin;
		@extend .flex-row;

		font-size: 2.5vmin;
		font-weight: bold;
	}

	.site-name {
		@extend .hover-brightness;
		@extend .flex-column;
		@extend .center-vertical;

		padding-left: calc(5vmin + 8px);
		
  		a {
			text-decoration: none;
			color : $primaryBlue;
		}
	}

	:global(.blue-icon) {
		fill : $primaryBlue;
	}
</style>

<div id="Nav">  
	<div on:click={navigateHome} >
		<Icon class={"blue-icon menu-icon pointer hover-brightness"} src={"meeting_icon.svg"} title="2 People. Meeting." />
	</div>
	<div class="site-name">
		<a href="/">Insight Gateway</a>
	</div>
	<div class="flex"></div>
</div>

<div name="divHrefB" style="height: 0px;width: 0px;overflow:hidden;">
<a href="/admin">B</a>
</div>